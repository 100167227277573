<template>
  <div class="content" style="text-align: left" @click="userClick = false">
    <Breadcrumb class="custom-breadcrumb">
      <BreadcrumbItem>人员管理</BreadcrumbItem>
      <BreadcrumbItem>管理员</BreadcrumbItem>
    </Breadcrumb>
    <div class="bg-shadow func-bar">
      <div class="button-container">
        <Button class="filter-btn btn-shadow" type="primary" @click="toAdd">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-xinzeng" />
          </svg>
          新增
        </Button>
        <Button class="filter-btn btn-shadow" type="primary" @click="handleDeleteAdministrators(selectAdmin)">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-shanchu" />
          </svg>
          删除
        </Button>
        <!--        <Button class="filter-btn btn-shadow" size="large" type="primary">-->
        <!--          <svg class="icon" aria-hidden="true">-->
        <!--            <use xlink:href="#icon-zhongzhimima1"></use>-->
        <!--          </svg>-->
        <!--          重置密码-->
        <!--        </Button>-->
      </div>
      <div class="search-container">
        <Input class="search" v-model="search.realName" search placeholder="请输入姓名" @on-search="searchAdministrators(true)" />
        <div class="advanced-container" @click="showAdvanced" @click.stop="userClick = !userClick">
          <span>高级搜索</span>
          <svg class="icon" :class="{ spread: isSpread }" aria-hidden="true">
            <use xlink:href="#icon-bottom" />
          </svg>
        </div>
        <div class="advanced-board bg-shadow" :class="{ show: isSpread }">
          <Form class="form" ref="modalForm" :model="search" :label-width="40">
            <FormItem label="姓名" prop="realName">
              <Input v-model="search.realName" />
            </FormItem>
            <FormItem label="角色" prop="roleId">
              <Select v-model="search.roleId" placeholder="选择角色">
                <Option v-for="role in roles" :value="role.id" :key="role.id">{{ role.name }}</Option>
              </Select>
            </FormItem>
            <FormItem label="邮箱" prop="email">
              <Input v-model="search.email" />
            </FormItem>
            <FormItem label="手机" prop="phone">
              <Input v-model="search.phone" />
            </FormItem>
          </Form>
          <div style="display: flex">
            <Button size="small" class="filter-btn search btn-shadow" type="primary" @click="resetForm('modalForm')">重置</Button>
            <Button size="small" class="filter-btn search btn-shadow" type="primary" @click="searchAdministrators(true)">搜索</Button>
          </div>
        </div>
      </div>
    </div>
    <Table class="iviewTable" height="500" :columns="tableHeader" :data="administrators.data || []" :loading="loading" @on-selection-change="select">
      <template slot-scope="{ row }" slot="role">
        <Tooltip placement="bottom" transfer style="width: 100%" max-width="100%">
          <div slot="content">
            <div>{{ row.roles.map(role => role.roleName).join('、') }}</div>
          </div>
          <div class="nowrap">
            {{ row.roles.map(role => role.roleName).join('、') }}
          </div>
        </Tooltip>
      </template>
      <template slot="status" slot-scope="{ row }">
        <div class="status-container">
          <div
            class="status"
            :style="{
              backgroundColor: row.status === CONSTANT.STATUS_NORMAL ? '#4ACACB' : '#CBCCCC'
            }"
          ></div>
          {{ row.status === CONSTANT.STATUS_NORMAL ? '正常' : '停用' }}
        </div>
      </template>
      <template slot="op" slot-scope="{ row }">
        <div>
          <Tooltip content="修改" placement="top" class="operation-tooltip">
            <JYIcon href="#icon-bianji" style="margin-right: 5px" @click="handleModifyAdministrator(row)" />
          </Tooltip>
          <Tooltip content="删除" placement="top" class="operation-tooltip">
            <JYIcon href="#icon-shanchu" @click="handleDeleteAdministrators([row])" />
          </Tooltip>
        </div>
      </template>
    </Table>
    <!--      <Table class="flex-table" stripe :columns="tableHeader" :data="administrators.data || []" :loading="loading" @on-selection-change="select" />-->
    <!--<div class="page-options">-->
    <!--共 {{administrators.total}} 项-->
    <!--</div>-->
    <Page
      class="yt-page"
      show-total
      show-sizer
      show-elevator
      :page-size-opts="[10, 20, 40, 100]"
      :total="administrators.total"
      :current="pageNumber + 1"
      @on-change="changePage"
      @on-page-size-change="changePageSize"
    />
    <AdminModal ref="addModel" v-model="addAdminModal" :isAdd="true" @on-success="searchAdministrators" />
    <AdminModal v-model="modifyAdminModal" :is-add="false" :administrator="modifyAdministrator" @on-success="searchAdministrators" />
    <DeleteModal v-model="deleteVisible" :title="`${deleteTitle}`" :loading="deleteLoading" @on-ok="deleteAdministrators"> </DeleteModal>
    <ConfirmDialog
      :value="deleteVisible"
      :title="'删除管理员'"
      :loading="deleteLoading"
      @on-ok="deleteAdministrators"
      @on-cancel="AdministratorsClose"
      @on-cancel-end="afterAdministratorsClose"
    >
      <div style="width: 238px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        删除角色会使得管理员与该角色的绑定关系失效， 您还要继续吗？
      </div>
    </ConfirmDialog>
    <!--    <DeleteModal v-model="showResetConfirm" :title="`确认要重置选中管理员的密码吗？`" @on-ok="resetPwdConfirm" :loading="resetLoading">-->
    <!--    </DeleteModal>-->
  </div>
</template>
<script>
import { Avatar, Tooltip } from 'view-design'
import defaultAvatar from '@assets/defaultAvatar.svg'
import administratorApi from '@api/administrator'
import AdminModal from './AdminModal'
import staffApi from '../../../../api/staff'
import RoleAPI from '@api/role'
import JYIcon from '@components/common/JYIcon'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
import YTIcon from '../../../common/YTIcon'

const STATUS_ALL = -1
const STATUS_NORMAL = 0
const STATUS_INVALID = 1

export default {
  components: {
    JYIcon,
    AdminModal,
    ConfirmDialog,
    YTIcon
  },
  data() {
    return {
      th: document.body.clientHeight - 250,
      CONSTANT: {
        STATUS_ALL,
        STATUS_INVALID,
        STATUS_NORMAL
      },
      roles: [],
      userClick: false,
      resetLoading: false,
      showResetConfirm: false,
      selectId: '',
      selectAdmin: [],
      addAdminModal: false,
      modifyAdminModal: false,
      modifyAdministrator: {},
      deleteTitle: '',
      deleteLoading: false,
      deleteVisible: false,
      deleteUserIds: [],
      isSpread: false,
      tableHeader: [
        // 每列宽度没有实际确定
        {
          type: 'selection',
          width: 65
        },
        {
          title: '',
          render: (h, data) => {
            return <Avatar src={data.row.avatar || defaultAvatar} />
          },
          className: 'avatar-column'
        },
        {
          title: '工号',
          key: 'jobNumber',
          className: 'name-column',
          ellipsis: true,
          tooltip: true
        },
        {
          title: '姓名',
          key: 'realName',
          className: 'name-column',
          ellipsis: true,
          tooltip: true
        },
        {
          title: '角色',
          tooltip: true,
          slot: 'role',
          className: 'role-column'
        },
        {
          title: '管理部门',
          key: 'departmentName',
          className: 'department-column',
          ellipsis: true,
          tooltip: true
        },
        {
          title: '邮箱',
          key: 'email',
          align: 'center',
          className: 'email-column',
          ellipsis: true,
          tooltip: true
        },
        {
          title: '手机',
          key: 'phone',
          align: 'center',
          className: 'phone-column',
          ellipsis: true,
          tooltip: true
        },
        {
          title: '状态',
          slot: 'status'
        },
        {
          title: '操作',
          className: 'operation-column',
          slot: 'op'
        }
      ],
      loading: false,
      pageNumber: 0,
      pageSize: 10,
      search: {
        realName: '',
        email: '',
        phone: '',
        roleId: null,
        managedDepartmentId: null,
        status: null
      },
      administrators: {
        total: 0,
        data: null
      }
    }
  },
  created() {
    this.getAllRoles()
  },
  mounted() {
    this.$nextTick(() => {
      this.searchAdministrators()
    })
  },
  methods: {
    afterAdministratorsClose() {
      this.resetLoading = false
    },
    AdministratorsClose() {
      this.deleteVisible = false
    },
    toAdd() {
      this.$refs.addModel.setQuery()
      this.addAdminModal = true
    },
    getAllRoles() {
      RoleAPI.getAllRoles().then(res => {
        this.roles = res.res
      })
    },
    resetPwd(id) {
      this.selectId = id
      this.showResetConfirm = true
    },
    resetPwdConfirm() {
      this.resetLoading = true
      let payload = {
        userIds: [this.selectId]
      }
      staffApi.resetPassword(payload).then(res => {
        this.$message.success('修改成功，请管理员查看手机短信接收到的新密码')
        this.showResetConfirm = false
        this.resetLoading = false
      })
    },
    select(val) {
      this.selectAdmin = val
    },
    showAdvanced() {
      this.isSpread = !this.isSpread
    },
    changePage(page) {
      this.pageNumber = page - 1
      this.searchAdministrators()
    },
    changePageSize(size) {
      this.pageSize = size
      this.searchAdministrators()
    },
    resetForm(form) {
      this.$refs[form].resetFields()
    },
    searchAdministrators(val) {
      this.loading = true
      if (val) {
        this.pageNumber = 0
        this.pageSize = 10
      }

      // 关键字搜索新接口
      const searchAdministratorDTO = {
        administratorCriteria: {
          realName: this.search.realName,
          email: this.search.email,
          phone: this.search.phone,
          roleId: this.search.roleId,
          managedDepartmentId: this.search.managedDepartmentId,
          status: this.search.status
        },
        pageCriteria: {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        },
        sortCriteria: {}
      }
      administratorApi
        .searchAdministrators(searchAdministratorDTO)
        .then(res => {
          this.administrators = res.res
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleModifyAdministrator(administrator) {
      this.modifyAdminModal = true
      this.modifyAdministrator = administrator
    },
    handleDeleteAdministrators(administrators) {
      if (administrators.length === 0) {
        this.$message.error('请选择要删除的管理员')
        return
      }
      const adminNames = administrators.map(v => v.realName).join('、')
      this.deleteTitle = `确定要删除管理员"${
        adminNames.length > 100 ? `${adminNames.substr(0, 100)}...等共 ${adminNames.length} 个` : adminNames
      }"吗？`
      this.deleteVisible = true
      this.deleteUserIds = administrators.map(v => v.userId)
    },
    deleteAdministrators() {
      this.deleteLoading = true

      administratorApi
        .batchDeleteAdministrators({
          administratorIds: this.deleteUserIds
        })
        .then(() => {
          this.searchAdministrators()
          this.deleteVisible = false
        })
        .finally(() => {
          this.deleteLoading = false
        })
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../theme/variables';
.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.func-bar {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 55px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 20px;

  .filter-btn {
    margin-right: 20px;
    min-width: 100px;
  }

  .search-container {
    display: flex;
    align-items: center;
    position: relative;

    .search ::v-deep .ivu-input {
      border: 0;
      border-bottom: 1px solid #ccc;
      border-radius: 0;

      &:focus {
        box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
      }
    }

    .advanced-container {
      flex: 1 0 auto;
      cursor: pointer;
      margin-left: 10px;
      font-size: 14px;
      padding-right: 20px;

      svg.icon {
        transition: all @default-transition;
        transform: rotate(0deg);
        margin-left: 8px;
      }

      svg.icon.spread {
        transform: rotate(180deg);
      }
    }

    .advanced-board {
      position: absolute;
      z-index: 1;
      top: 56px;
      right: 0;
      width: 340px;
      height: 0;
      overflow: hidden;
      border-right: solid 5px @primary-color;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      transition: all @default-transition;

      &.show {
        height: auto;
        padding-bottom: 15px;
      }

      .search {
        margin-right: 20px;
        height: 40px;
        border-radius: 4px;
      }

      .form {
        width: 280px;
        margin-top: 20px;
      }
    }
  }
}

.table-container {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ::v-deep .flex-table {
    border-bottom: 1px solid #e8eaec;

    .status-container {
      font-size: 14px;
      color: #555555;
      display: flex;
      align-items: center;

      .status {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 4px;
      }
    }

    .operation-tooltip {
      margin-right: 10px;
      cursor: pointer;
      color: @primary-color;
      transition: all @default-transition;

      &:hover {
        color: @primary-hover-color;
      }
    }
    .avatar-column {
      width: 8%;
      max-width: 200px;
    }

    .name-column {
      width: 10%;
      flex: 1 1 auto;
    }

    .role-column {
      width: 15%;
      flex: 1 1 auto;
    }

    .department-column {
      width: 15%;
      flex: 1 1 auto;
    }

    .email-column {
      width: 15%;
      flex: 1 1 auto;
    }

    .phone-column {
      width: 15%;
      flex: 1 1 auto;
    }

    .status-column {
      width: 10%;
    }

    .operation-column {
      width: 10%;
      flex: 1 1 auto;
    }
  }

  .page {
    flex: 0 0 auto;
    padding: 10px;
    font-size: 14px;

    .page-options {
      float: left;
      height: 32px;
      line-height: 32px;
    }
  }
}
::v-deep .ivu-select-item {
  white-space: pre-wrap;
}
.iviewTable ::v-deep .nowrap {
  font-size: 14px;
  color: #555555;
  white-space: revert;
}
</style>
